import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import EmailIcon from 'src/svgs/email.secondary.svg';
import LocationIcon from 'src/svgs/location.secondary.svg';
import PhoneIcon from 'src/svgs/smartphone.secondary.svg';
import './google-maps.scss';

function BlockContentContact() {
  const data = useStaticQuery(graphql`
    {
      allSanityContactInformation {
        nodes {
          ...getContactInformation
        }
      }
    }
  `);

  const contactInformations = data.allSanityContactInformation.nodes.map((item) => ({
    id: item._id,
    place: item.place ?? '[place]',
    address: item.address ?? '[address]',
    addressUrl: item.addressUrl,
    email: item.email ?? '[email]',
    phone: item.phone ?? '[phone]',
  }));

  const [active, setActive] = useState(contactInformations[0]);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const paginate = (element) => {
    if (active.id !== element.id) {
      setShouldAnimate(true);
      setActive(element);
      setTimeout(() => {
        setShouldAnimate(false);
      }, 200);
    }
  };

  const infoLinkClasses = 'd-flex align-items-center text-white text-size-xs font-weight-bold';

  const infoTextClasses = `${shouldAnimate && 'img-fade-in'} ml-2 color`;

  return (
    <Container fluid>
      <Row className="google-maps-pic flex-nowrap">
        <Col xs={12} className="w-full px-0 " />
        <div className="ml-n100 p-sm-5 w-100 p-4">
          <Container>
            {contactInformations.map((item) => (
              <Button
                key={item.id}
                variant="link-secondary"
                onClick={() => paginate(item)}
                className={[item.id === active.id && 'active', 'mr-sm-4 mr-md-6 h3 mr-2'].join(' ')}>
                {item.place}
              </Button>
            ))}
            <div className="mt-4 py-6 px-0">
              <div className="px-0 pb-2">
                <address className="mb-0">
                  <span title="Google Maps" className={infoLinkClasses}>
                    <div>
                      <LocationIcon />
                    </div>
                    <span className={infoTextClasses}>{active.address}</span>
                  </span>
                </address>
              </div>
              <div className="py-2 px-0">
                <a href={`mailto:${active.email}`} title="eMail" className={infoLinkClasses}>
                  <div>
                    <EmailIcon />
                  </div>
                  <span className={infoTextClasses}>{active.email}</span>
                </a>
              </div>
              <div className="px-0 pt-2">
                <a href={`tel:${active.phone}`} title="Phone" className={infoLinkClasses}>
                  <div>
                    <PhoneIcon />
                  </div>
                  <span className={infoTextClasses}>{active.phone}</span>
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Row>
    </Container>
  );
}

export default BlockContentContact;
